
import { Options, Prop, Vue } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { IStudentEducationWithTheory } from "@/interfaces/IStudentEducationWithTheory";

@Options({
  components: { EducationTabs },
})
export default class PreliminaryImg extends Vue {
  @Prop({ default: 0 })
  private countPreExamsDone!: any;

  @Prop()
  private selectedStudentEducation!: IStudentEducationWithTheory;

  public get preExamsCountDone(): number {
    return this.countPreExamsDone >= 4 ? 4 : this.countPreExamsDone; // * 25;
  }

  public get learnStatusPercent(): number {
    return this.selectedStudentEducation ? this.selectedStudentEducation.learnStatus : 0;
  }
}
