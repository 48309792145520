
import { Options, Prop } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import StudentTheoryEducationMixin from "@/mixins/StudentEducations/StudentTheoryEducationMixin";
import TheoryImg from "@/components/Pages/Home/Theory/TheoryImg/TheoryImg.vue";
import { useIonRouter } from "@ionic/vue";
import { IStudentEducationWithTheory } from "@/interfaces/IStudentEducationWithTheory";
import { PropType } from "vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import TheoryImgPositive from "@/components/Pages/Home/Theory/TheoryImg/TheoryImgPositive.vue";

const TheoryQuestionsModule = namespace("theory-questions");
const StudentQuestionAnswersModule = namespace("student-question-answers");
const StudentEducationModule = namespace("student-education");

@Options({
  components: {
    TheoryImgPositive,
    TheoryImg,
    EducationTabs,
  },
})
export default class TheoryBlock extends mixins(StudentTheoryEducationMixin) {
  private ionRouter = useIonRouter();

  @Prop({ default: "theory" })
  public theoryLink!: any;

  @Prop({ default: () => false })
  public disabled!: boolean;

  @Prop({
    type: Array as PropType<Array<IStudentEducationWithTheory>>,
    default: () => [],
  })
  public studentEducations!: IStudentEducationWithTheory[];

  @Prop({ default: () => false })
  public basicView!: boolean;

  @TheoryQuestionsModule.Action("learningMode/findOne")
  public getLearningModeAction: any;

  @TheoryQuestionsModule.Getter("learningMode/getDataItem")
  public learningModeGet: any;

  @TheoryQuestionsModule.Getter("learningMode/getIsLoading")
  public learningModeGetLoading: any;

  @TheoryQuestionsModule.Action("learningModeCreate/create")
  public saveLearningModeAction: any;

  @TheoryQuestionsModule.Getter("learningModeCreate/getSuccess")
  public setLearningModeSuccess: any;

  @TheoryQuestionsModule.Getter("learningModeCreate/getIsLoading")
  public setLearningModeLoading: any;

  @StudentQuestionAnswersModule.Action("getOne")
  public getStatsAction: any;

  @StudentQuestionAnswersModule.Action("resetSave")
  public resetStatsAction: any;

  @StudentQuestionAnswersModule.Getter("getDataItem")
  public stats: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public statsLoading: any;

  @StudentEducationModule.Action("withTheory/findAll")
  public findAllStudentEducationWithTheory!: (options: ICrudOptions) => Promise<void>;

  private learningModeSwitchState = false;
  public async mounted() {
    if (!(this.selectedEducation && this.selectedEducation.id)) {
      this.$toasted.info("You haven't got any theory for this education!");
      this.ionRouter.push({ name: "Home" });
      return;
    }
    if (this.selectedEducation && this.selectedEducation.id) {
      await this.getLearningModeAction({
        resource: "theory-questions",
        extraPath: "learning-mode",
        hideSuccessMessage: true,
        params: {
          studentEducationId: this.selectedEducation.id,
        },
      });
      const modeId = this.learningModeGet.id;
      this.learningMode = modeId;
      this.learningModeSwitchState = modeId === 2;
      // save to store learning mode
      this.setLearningModeAction(modeId);
    }
  }

  private loadStats(education: any): void {
    if (!education || !education.id) {
      return;
    }
    this.getStatsAction({
      resource: "student-question-answers",
      extraPath: "stats",
      hideSuccessMessage: true,
      params: {
        studentEducationId: education.id,
        learningModeId: this.learningMode ? this.learningMode : 1,
      },
    });
  }
  private onEducationClassChanged(educationClass: any): void {
    if (educationClass && educationClass.id) {
      this.selectEducation(educationClass);
      this.loadStats(educationClass);
    }
  }
  private async onChangeLearningMode(learningModeSwitchState: boolean): Promise<void> {
    this.setLearningModeAction(this.learningModeSwitchState ? "2" : "1");

    if (!this.selectedEducation) {
      return;
    }

    await this.saveLearningModeAction({
      resource: "theory-questions/learning-mode",
      hideSuccessMessage: true,
      params: {
        studentEducationId: this.selectedEducation.id,
        learningModeId: this.learningMode,
      },
    });

    if (true === this.setLearningModeSuccess) {
      this.loadStats(this.selectedEducation);
    }

    this.onChangeLearningModeCheckbox();
  }

  public get isTheoryQuestionLink(): boolean {
    return this.theoryLink == "theory-questions";
  }

  private onClickCard(): void {
    const location = {
      name: this.isTheoryQuestionLink ? "TheoryQuestions" : "Theory",
    };
    if (this.isTheoryQuestionLink) {
      Object.assign(location, {
        params: {
          studentEducation: this.selectedEducation ? this.selectedEducation.id : 0,
        },
      });
    }
    this.ionRouter.navigate(location, "forward", "push");
  }

  private get studentEducationParam(): number {
    return this.selectedEducation && this.selectedEducation.id ? this.selectedEducation.id : 0;
  }

  private async onChangeLearningModeCheckbox(): Promise<void> {
    await this.findAllStudentEducationWithTheory({
      resource: "/student-educations/student/current",
      descriptionField: "",
    });
  }

  public toastMessage() {
    this.$toasted.error(
      "Glückwunsch! Du hast keine offenen Fragen mehr. Um weiterzulernen, klicke bitte direkt in einen Würfel auf der Prüfungsfragenwaage. Alternativ kannst du auch beliebig viele Vorprüfungen absolvieren oder die Spielwiese nutzen."
    );
  }

  public get filteredEducations() {
    return this.studentEducations?.filter((education: any) => education.theoryRequired) || [];
  }
}
