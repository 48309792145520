
import { Options, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import StudentTheoryEducationMixin from "@/mixins/StudentEducations/StudentTheoryEducationMixin";
import TheoryImgPositive from "@/components/Pages/Home/Theory/TheoryImg/TheoryImgPositive.vue";
import TheoryImgNegative from "@/components/Pages/Home/Theory/TheoryImg/TheoryImgNegative.vue";
import TheoryImgNeutral from "@/components/Pages/Home/Theory/TheoryImg/TheoryImgNeutral.vue";

@Options({
  components: {
    TheoryImgNeutral,
    TheoryImgNegative,
    TheoryImgPositive,
  },
})
export default class TheoryImg extends mixins(StudentTheoryEducationMixin) {
  @Prop()
  public studentEducation!: any;
  @Prop()
  public stats: any;
  @Prop()
  public loading: any;
}
