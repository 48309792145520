import { Options, Vue } from "vue-property-decorator";

@Options({})
export default class TheoryImgMixin extends Vue {
  protected openQuestion(questionType: string, studentEducationId: string): void {
    this.$router.push({
      name: "TheoryQuestionBoxes",
      params: { id: questionType, studentEducation: studentEducationId },
    });
  }
}
