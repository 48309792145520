
import { Options, Prop } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import TheoryImgMixin from "@/mixins/TheoryImgMixin";
import TransparentSvgRect from "@/components/TransparentSvgRect.vue";
import { LearningModeEnum } from "@/enums/LearningModeEnum";

const StudentQuestionAnswersModule = namespace("student-question-answers");

@Options({
  components: {
    TransparentSvgRect,
  },
})
export default class TheoryImgNegative extends mixins(TheoryImgMixin) {
  @Prop()
  public studentEducation!: any;
  @Prop()
  public learningMode!: any;
  @Prop()
  public stats: any;
  @Prop()
  public loading: any;

  get incorrectlyAnsweredX(): number {
    if (!this.stats || !this.stats.incorrectlyAnswered) {
      return -7;
    }
    if (this.stats.incorrectlyAnswered >= 100) {
      return -22;
    }
    if (this.stats.incorrectlyAnswered >= 10) {
      return -15;
    }
    return -7;
  }

  get readyForExamX(): number {
    if (!this.stats || !this.stats.readyForExam) {
      return -5;
    }
    if (this.stats.readyForExam >= 100) {
      return -16;
    }
    if (this.stats.readyForExam >= 10) {
      return -8;
    }
    return -5;
  }

  get oneTimeAnsweredX(): number {
    if (!this.stats || !this.stats.correctlyAnswered) {
      return 18;
    }
    if (this.stats.correctlyAnswered >= 100) {
      return 6;
    }
    if (this.stats.correctlyAnswered >= 10) {
      return 15;
    }
    return 18;
  }

  get remainingMidX(): number {
    if (!this.stats || !this.stats.remaining) {
      return 18;
    }

    if (this.stats.remaining >= 1000) {
      return -2;
    }
    if (this.stats.remaining >= 100) {
      return 6;
    }
    if (this.stats.remaining >= 10) {
      return 15;
    }
    return 18;
  }

  get remainingX(): number {
    if (!this.stats || !this.stats.remaining) {
      return 18;
    }
    if (this.stats.remaining >= 1000) {
      return -2;
    }
    if (this.stats.remaining >= 100) {
      return 6;
    }
    if (this.stats.remaining >= 10) {
      return 15;
    }
    return 18;
  }

  public get learningModeDoubleCheck() {
    return this.learningMode == LearningModeEnum.doubleCheck;
  }
}
